<template>
  <div class="content-area">
    <hero-bar>
      {{ $t('users') }}
      <b-tooltip slot="right" :label="$t('refresh')" type="is-dark" position="is-bottom">
        <b-button
          id="refresh-button"
          class="mr-10"
          size="is-circle"
          type="is-link"
          outlined
          :class="{ 'is-loading': refreshLoading }"
          icon-left="refresh"
          @click="refresh"
        />
      </b-tooltip>
      <b-tooltip slot="right" :label="$t('importExcel')" type="is-dark" position="is-bottom">
        <b-button
          v-if="checkPermission('create_user')"
          class="mr-10 is-hidden-mobile"
          size="is-circle"
          type="is-link"
          outlined
          icon-left="application-import"
          @click="excelImportModalActive = true"
        />
      </b-tooltip>
      <b-tooltip slot="right" :label="$t('exportExcel')" type="is-dark" position="is-bottom">
        <b-button
          class="mr-10 is-hidden-mobile"
          size="is-circle"
          type="is-link"
          outlined
          :class="{ 'is-loading': excelLoading }"
          icon-left="file-excel"
          @click="exportExcel"
        />
      </b-tooltip>
      <b-button
        v-if="checkPermission('create_user')"
        slot="right"
        tag="router-link"
        :to="{ name: 'users.create' }"
        icon-left="plus-circle"
        type="is-link"
      >
        {{ $t('createUser') }}
      </b-button>
    </hero-bar>

    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced">
        <smart-table
          ref="table"
          data-url="users"
          default-sort="id"
          :loading.sync="refreshLoading"
          :excelLoading.sync="excelLoading"
        >
          <template slot="action-column" slot-scope="{ row }">
            <b-button
              outlined
              size="is-small"
              class="button-purble btn-details"
              tag="router-link"
              :to="{ name: 'users.edit', params: { id: row.id }}"
              icon-left="arrow-right-bold"
            >
              {{ $t('edit') }}
            </b-button>
          </template>
        </smart-table>
      </card-component>
    </section>
    <b-modal
      :active.sync="excelImportModalActive"
      :can-cancel="['escape', 'x']"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <excel-import-modal />
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'Users',
  title: ({ $i18n }) => $i18n.t('titles.users'),
  components: {
    HeroBar: () => import('@/components/HeroBar'),
    CardComponent: () => import('@/components/CardComponent'),
    SmartTable: () => import('@/components/SmartTable'),
    ExcelImportModal: () =>
      import(/* webpackPrefetch: true */ '@/components/ExcelImportModal'),
  },
  data () {
    return {
      searchable: false,
      selectableRoles: [],
      selectableDepartments: [],
      excelLoading: false,
      dataUrl: '',
      excelImportModalActive: false,
      refreshLoading: false,
    };
  },
  methods: {
    refresh () {
      document.getElementById('refresh-button').blur()
      this.refreshLoading = true
      this.$refs.table.loadData()
    },
    exportExcel () {
      this.excelLoading = true
      this.$refs.table.excelExport()
    },
    actionLoadingChange (e) {
      this.refreshLoading = e
    },
  },
};
</script>
